<template>
<div>
  <header class="relative rounded-xl overflow-auto p-8 flex justify-center items-center">
    <NuxtLink class="text-4xl" to="/">Agentsmith</NuxtLink>
  </header>
  <UMain>
    <slot />
  </UMain>
  <UFooter>
    <template #center>
      <p class="text-gray-500 dark:text-gray-100 text-sm">
        Copyright © {{ new Date().getFullYear() }}. All rights reserved.
      </p>
    </template>
  </UFooter>
</div>
</template>

<script setup lang="ts">
</script>

<style scoped>
</style>